import React, { useState, useEffect, useMemo } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Helmet } from "react-helmet";
import {
  GooglePlayButton,
  ButtonsContainer,
  AppStoreButton,
} from "react-mobile-app-button";
import {
  MdOutlineFileDownload,
  MdOutlineModeEditOutline,
} from "react-icons/md";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";
import { FaHeart, FaEye, FaEdit, FaPlay } from "react-icons/fa";
import { HiOutlineMenu } from "react-icons/hi";
import { TiHeartOutline } from "react-icons/ti";
import { GoLinkExternal } from "react-icons/go";

import EnhanceLeft1 from "./assets/enhance-left-1.png";
import EnhanceRight1 from "./assets/enhance-right-1.png";
import EnhanceCenter1 from "./assets/enhance-center-1.png";

import EnhanceLeft2 from "./assets/enhance-left-2.png";
import EnhanceRight2 from "./assets/enhance-right-2.png";
import EnhanceCenter2 from "./assets/enhance-center-2.png";

import Logo from "./assets/logo.png";
import Personel from "./assets/personel.webp";
import PersonelOther from "./assets/personel-2.webp";
import Modal from "./components/Modal";

import arrowFirst from "./assets/arrow_icon.png";
import arrowSecond from "./assets/arrow_icon_second.png";

import styles from "./App.module.scss";
import WhyUse from "./WhyUse";
import Features from "./Features";
import Enhance from "./Enhance";
import Footer from "./Footer";
import Pricing from "./Pricing";
import Review from "./Review";
import Contact from "./Contact";

const App = () => {
  const APKUrl = "https://play.google.com/store/apps/details?id=host";
  const IOSUrl = "https://apps.apple.com/us/app/expo-go/id982107779";
  const loginUrl = "https://app.magicversion.com";

  const [headline, setHeadline] = useState("Editor");
  const [subheadline, setSubheadline] = useState("E-commerce");
  const [headlineIndex, setHeadlineIndex] = useState(0);
  const [subheadlineIndex, setSubheadlineIndex] = useState(0);
  const [activeLink, setActiveLink] = useState("");
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // New state for background images
  const [backgroundIndex, setBackgroundIndex] = useState(0);

  const headlines = useMemo(
    () => ["Editor", "Enhancer", "Adjustment", "Retouching", "Styling"],
    []
  );
  const subheadlines = useMemo(
    () => [
      "E-commerce",
      "Your Photos",
      "Product Images",
      "Business Photos",
      "Marketing",
    ],
    []
  );

  // Background image sets
  const backgroundImages = [
    {
      left: EnhanceLeft1,
      center: EnhanceCenter1,
      right: EnhanceRight1,
    },
    {
      left: EnhanceLeft2,
      center: EnhanceCenter2,
      right: EnhanceRight2,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setHeadlineIndex((prev) => (prev + 1) % headlines.length);
      setSubheadlineIndex((prev) => (prev + 1) % subheadlines.length);
      setBackgroundIndex((prev) => (prev + 1) % backgroundImages.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [headlines.length, subheadlines.length, backgroundImages.length]);

  useEffect(() => {
    setHeadline(headlines[headlineIndex]);
    setSubheadline(subheadlines[subheadlineIndex]);
  }, [headlineIndex, subheadlineIndex, headlines, subheadlines]);

  const handleSetActive = (to) => {
    setActiveLink(to);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>AI-Powered Photo Editor and Generator | MagicVersion</title>
        <meta
          name="description"
          content="Transform and generate your product photos with MagicVersion. Our AI-driven platform enhances clarity, adjusts lighting, removes backgrounds, and generates new images. Perfect for e-commerce businesses to make professional and appealing images."
        />
        <meta
          name="keywords"
          content="AI Photo Editor, AI Photo Enhancer, E-commerce Photo Editing, Product Image Retouching, AI Photo Adjustment, AI Retouching, Background Removal, Background Generator, Object Removal, Professional Photo Editing, AI Image Generation"
        />
        <meta
          property="og:title"
          content="AI-Powered Photo Editor and Generator | MagicVersion"
        />
        <meta
          property="og:description"
          content="Transform and generate your product photos with MagicVersion. Enhance clarity, adjust lighting, remove backgrounds, and generate new images with our AI-driven platform. Ideal for e-commerce businesses."
        />
        <meta property="og:image" content="path/to/your/image.jpg" />
        <meta property="og:url" content="https://magicversion.com" />
        <link rel="canonical" href="https://magicversion.com" />
      </Helmet>

      <nav className={styles.navbar}>
        <div className={styles.navContent}>
          <div className={styles.logo}>
            <img
              src={Logo}
              alt="MagicVersion logo"
              style={{ width: "170px" }}
            />
          </div>
          <HiOutlineMenu
            onClick={() => {
              setVisibleMenu(!visibleMenu);
            }}
            className={styles.menuIcon}
            size={30}
          />

          <ul className={styles.navLinks}>
            <li>
              <ScrollLink
                offset={-120}
                to="features"
                smooth={true}
                duration={500}
                onSetActive={() => handleSetActive("features")}
                className={activeLink === "features" ? styles.activeLink : ""}
              >
                Features
              </ScrollLink>
            </li>
            <li
              onClick={() => {
                handleSetActive("pricing");
              }}
              className={activeLink === "pricing" ? styles.activeLink : ""}
            >
              <ScrollLink
                offset={-120}
                to="pricing"
                smooth={true}
                duration={500}
              >
                Pricing
              </ScrollLink>
            </li>
            <li
              onClick={toggleModal}
              className={activeLink === "api" ? styles.activeLink : ""}
            >
              API
            </li>

            <li
              onClick={() => handleSetActive("contact")}
              className={activeLink === "contact" ? styles.activeLink : ""}
            >
              <ScrollLink
                offset={-120}
                to="contact"
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
                className={activeLink === "contact" ? styles.activeLink : ""}
              >
                Contact
              </ScrollLink>
            </li>
            <button
              onClick={() => {
                window.open(loginUrl, "_blank", "noopener,noreferrer");
              }}
              className={`${styles.primaryButton} ${styles.loginButton} `}
            >
              Log in
            </button>
            <button
              // onClick={() => {
              //   window.open(loginUrl, "_blank", "noopener,noreferrer");
              // }}
              className={styles.primaryButton}
            >
              Get Started
            </button>
          </ul>
        </div>
        {visibleMenu && (
          <div className={styles.mobileMenuContent}>
            <div
              onClick={() => {
                handleSetActive("pricing");
              }}
              className={activeLink === "pricing" ? styles.activeLink : ""}
            >
              <ScrollLink
                onClick={() => {
                  setVisibleMenu(!visibleMenu);
                }}
                offset={-120}
                to="pricing"
                smooth={true}
                duration={500}
              >
                Pricing
              </ScrollLink>
            </div>
            <li
              onClick={toggleModal}
              className={activeLink === "api" ? styles.activeLink : ""}
            >
              API
            </li>

            <div>
              <ScrollLink
                onClick={() => {
                  setVisibleMenu(!visibleMenu);
                }}
                offset={-120}
                to="features"
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
                className={activeLink === "features" ? styles.activeLink : ""}
              >
                Features
              </ScrollLink>
            </div>
            <div>
              <ScrollLink
                onClick={() => {
                  setVisibleMenu(!visibleMenu);
                }}
                offset={-120}
                to="contact"
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
                className={activeLink === "contact" ? styles.activeLink : ""}
              >
                Contact
              </ScrollLink>
            </div>
            <button
              onClick={() => {
                window.open(loginUrl, "_blank", "noopener,noreferrer");
              }}
              className={`${styles.primaryButton} ${styles.loginButton} `}
            >
              Log in
            </button>
            <button
              // onClick={() => {
              //   window.open(loginUrl, "_blank", "noopener,noreferrer");
              // }}
              className={styles.primaryButton}
            >
              Get Started
            </button>
          </div>
        )}
      </nav>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.madeBy}>
            <img
              width={20}
              src="https://framerusercontent.com/images/H5Bijhs1Rxe0NPhIXB4tdDEOM8.png"
              alt="Moonalisa logo"
            />
            <span>Made By Moonalisa</span>
            <span className={styles.visit}>
              visit
              <GoLinkExternal />
            </span>
          </div>
          <h1>
            AI-Powered Photo{" "}
            <span className={styles.gradientText}>{headline}</span> for{" "}
            <span className={styles.gradientText}>{subheadline}</span>
          </h1>
          <div className={styles.description}>
            <p className={styles.mobileDescription}>
              MagicVersion enhances clarity, adjusts lighting, and removes
              backgrounds.
            </p>
            <p className={styles.desktopDescription}>
              MagicVersion is an AI-driven platform that transforms your product
              photos by enhancing clarity, adjusting lighting, removing
              backgrounds, and removing unwanted objects. Ideal for e-commerce
              businesses, our tool ensures your images look professional and
              appealing, helping you attract more customers effortlessly.
            </p>
          </div>
          <div className={styles.actions}>
            <ButtonsContainer>
              <GooglePlayButton
                height={35}
                url={APKUrl}
                theme={"light"}
                className={styles.customStyle}
              />
              <AppStoreButton
                height={35}
                url={IOSUrl}
                theme={"light"}
                className={styles.customStyle}
              />
            </ButtonsContainer>
          </div>
        </header>
        <div className={styles.content}>
          <div className={styles.profileCardWrapper}>
            <div className={styles.iconRow}>
              <div className={styles.iconWrapper}>
                <FaHeart size={16} color="#F4541D" />
              </div>
              <div className={styles.iconWrapper}>
                <FaEye size={16} color="#0284FE" />
              </div>
              <div className={styles.iconWrapper}>
                <FaEdit size={16} color="#F7AF22" />
              </div>
              <div className={styles.iconWrapper}>
                <FaPlay size={16} color="#F4541D" />
              </div>
              <div className={styles.iconWrapper}>
                <MdOutlineFileDownload size={24} color="#36AB80" />
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${backgroundImages[backgroundIndex].left})`,
              }}
              className={styles.profileCard}
            >
              <div className={styles.profile}>
                <div className={styles.buttons}>
                  <button className={styles.messageButton}>
                    <span>Download</span>
                    <MdOutlineFileDownload size={20} />
                  </button>
                  <button className={styles.followButton}>
                    <span>Adjustments</span>
                    <HiMiniAdjustmentsHorizontal />
                  </button>
                </div>
              </div>
              <img className={styles.arrowFirst} src={arrowFirst} alt="" />
            </div>
          </div>
          <div className={styles.imageCardWrapper}>
            <div
              style={{
                backgroundImage: `url(${backgroundImages[backgroundIndex].center})`,
              }}
              className={styles.imageCard}
            ></div>
            <div className={`${styles.imageCardBottom}`}>
              <div className={styles.leftSide}>
                <img src={PersonelOther} alt="John Nou, Product Manager" />
                <div className={styles.nameInfo}>
                  <span>John Nou</span>
                  <p>Product Manager</p>
                </div>
              </div>
              <div className={styles.rightSide}>
                <MdOutlineModeEditOutline size={25} />
              </div>
            </div>
            <img className={styles.arrowSecond} src={arrowSecond} alt="" />
          </div>
          <div
            style={{
              backgroundImage: `url(${backgroundImages[backgroundIndex].right})`,
            }}
            className={styles.loginCard}
          >
            <div className={styles.loginCardBottom}>
              <div className={styles.leftSide}>
                <img src={Personel} alt="Alex Sufforsin, Team Member" />
                <div>Alex Sufforsin</div>
              </div>
              <div className={styles.rightSide}>
                <TiHeartOutline />
              </div>
            </div>
          </div>
        </div>
        <WhyUse />
        {/* <GenerateBackground /> */}
        <Enhance />
        <Features />
        <div id="pricing">
          <Pricing />
        </div>
        <Review />
        <div id="contact">
          <Contact />
        </div>
        <Footer />
      </div>
      <Modal show={showModal} onClose={toggleModal} />
    </div>
  );
};

export default App;
