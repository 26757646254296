import React, { useState, useEffect } from "react";
import styles from "./styles/WhyUse.module.scss";
import { FaRegImages } from "react-icons/fa";
import { TbHandMove, TbTrash } from "react-icons/tb";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { PiTextboxBold } from "react-icons/pi";
import { PiResizeBold } from "react-icons/pi";

import BgRemove1 from "./assets/covers/Bg_Remove_1.jpeg";
import BgRemove2 from "./assets/covers/Bg_Remove_2.jpeg";
import BgRemove3 from "./assets/covers/Bg_Remove_3.jpeg";
import BgReplace1 from "./assets/covers/Bg_Replace_1.jpg";
import BgReplace2 from "./assets/covers/Bg_Replace_2.png";
import BgReplace3 from "./assets/covers/Bg_Replace_3.jpg";
import Outpainting1 from "./assets/covers/Outpainting_1.jpg";
import Outpainting2 from "./assets/covers/Outpainting_2.jpg";
import Outpainting3 from "./assets/covers/Outpainting_3.jpg";
import RetouchProduct1 from "./assets/covers/Retouch_Product_1.jpg";
import RetouchProduct2 from "./assets/covers/Retouch_Product_2.jpg";
import RetouchProduct3 from "./assets/covers/Retouch_Product_3.jpg";
import TextRemover1 from "./assets/covers/Text_Remover_1.jpg";
import TextRemover2 from "./assets/covers/Text_Remover_2.jpg";
import TextRemover3 from "./assets/covers/Text_Remover_3.jpg";
import ObjectRemoval1 from "./assets/covers/Object_Removal_1.jpg";
import ObjectRemoval2 from "./assets/covers/Object_Removal_2.jpg";
import ObjectRemoval3 from "./assets/covers/Object_Removal_3.jpg";

const featuresData = [
  {
    icon: FaWandMagicSparkles,
    color: "#FFC148",
    title: "AI Product Enhancer",
    description:
      "Enhance product images with AI for a professional look. Perfect for jewelry like rings and necklaces.",
    images: [
      { src: RetouchProduct1, alt: "Enhanced product image of a ring with AI" },
      { src: RetouchProduct2, alt: "AI enhanced jewelry product image" },
      {
        src: RetouchProduct3,
        alt: "Professional product photo enhanced with AI",
      },
    ],
  },
  {
    icon: FaRegImages,
    color: "#FF6B38",
    title: "AI Background Generator",
    description:
      "Generate beautiful backgrounds for your products automatically using AI. Create stunning visuals with ease.",
    images: [
      { src: BgReplace1, alt: "AI generated background for product image" },
      { src: BgReplace2, alt: "Product image with AI generated background" },
      { src: BgReplace3, alt: "Stunning AI background for product photo" },
    ],
  },
  {
    icon: TbTrash,
    color: "#1CDB9E",
    title: "AI Background Removal",
    description:
      "Remove backgrounds from your product images with AI. Make your products stand out effortlessly.",
    images: [
      { src: BgRemove1, alt: "AI removed background from product image" },
      { src: BgRemove2, alt: "Product photo with AI background removal" },
      { src: BgRemove3, alt: "Clean product image with AI background removal" },
    ],
  },
  {
    icon: TbHandMove,
    color: "#43A4FF",
    title: "AI Object Removal",
    description:
      "Remove unwanted objects from your images seamlessly with AI. Enhance image quality effortlessly.",
    images: [
      { src: ObjectRemoval1, alt: "AI removed unwanted object from image" },
      { src: ObjectRemoval2, alt: "Image with AI object removal" },
      { src: ObjectRemoval3, alt: "Clean photo with AI object removal" },
    ],
  },
  {
    icon: PiResizeBold,
    color: "#FF4337",
    title: "AI Image Outpainting",
    description: "Enhance photo resolution with AI upscaling.",
    images: [
      { src: Outpainting1, alt: "AI enhanced photo resolution" },
      { src: Outpainting2, alt: "High-resolution image with AI upscaling" },
      { src: Outpainting3, alt: "AI upscaled photo" },
    ],
  },
  {
    icon: PiTextboxBold,
    color: "#7400F3",
    title: "AI Auto Text Remover",
    description:
      "Automatically detect and remove unwanted text from images using AI.",
    images: [
      { src: TextRemover1, alt: "AI removed text from image" },
      { src: TextRemover2, alt: "Image with AI text removal" },
      { src: TextRemover3, alt: "Clean image with AI text removal" },
    ],
  },
];

const WhyUse = () => {
  return (
    <div className={styles.whyUseContainer}>
      <h2 className={styles.outTitle}>
        Why Use MagicVersion for AI Product Image Editing?
      </h2>
      <p className={styles.outDesc}>
        MagicVersion helps you edit your product photos using cutting-edge AI
        technology. Optimize your images for e-commerce success.
      </p>
      <div className={styles.features}>
        {featuresData.map((feature, index) => (
          <FeatureCard key={index} feature={feature} />
        ))}
      </div>
    </div>
  );
};

const FeatureCard = ({ feature }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % feature.images.length
      );
    }, 5000); // 5 saniye aralıklarla geçiş yap

    return () => clearInterval(interval);
  }, [feature.images.length]);

  return (
    <div className={styles.featureCard}>
      <div className={styles.images}>
        {feature.images.map((image, imgIndex) => (
          <img
            key={imgIndex}
            src={image.src}
            alt={image.alt}
            className={
              imgIndex === currentImageIndex
                ? styles.activeImage
                : styles.inactiveImage
            }
          />
        ))}
      </div>

      <div className={styles.icon}>
        <h3>{feature.title}</h3>
        <div className={styles.iconItems}>
          <feature.icon
            color={feature.color}
            size={30}
            alt={`${feature.title} icon`}
          />
        </div>
      </div>
      <p>{feature.description}</p>
    </div>
  );
};

export default WhyUse;
