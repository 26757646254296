import React from "react";
import styles from "./styles/Features.module.scss";
import {
  FaSyncAlt,
  FaMagic,
  FaUserFriends,
  FaPaintBrush,
  FaCloud,
  FaSun,
} from "react-icons/fa";
import RotateImage from "./assets/rotate_image.png";

const featuresData = [
  {
    icon: FaSun,
    title: "Brightness and Contrast Adjustment",
    description:
      "Easily adjust the brightness and contrast of your images for perfect lighting.",
  },
  {
    icon: FaUserFriends,
    title: "Easy and Understandable User Interface",
    description:
      "Our simple and user-friendly interface ensures a seamless editing experience.",
  },
  {
    icon: FaPaintBrush,
    title: "Customization Options",
    description:
      "Multiple customization options to suit your editing style and needs.",
  },
  {
    icon: FaCloud,
    title: "No Installation Required",
    description:
      "Access all AI-powered features online without needing to install any software.",
  },
  {
    icon: FaSyncAlt,
    title: "Real-time Image Processing",
    description:
      "Experience fast and efficient real-time image processing with our AI tools.",
  },
  {
    icon: FaMagic,
    title: "AI-Powered Image Effects",
    description:
      "Apply AI-powered effects to enhance your images effortlessly and professionally.",
  },
];

const Features = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.gradientText}>Our Features</h2>
      <div className={styles.wrapper}>
        <section className={styles.imageSection}>
          <img
            style={{
              position: "relative",
              left: "-6px",
            }}
            src={RotateImage}
            alt="AI-Powered Image Enhancement Example"
          />
        </section>
        <section className={styles.textSection}>
          <div className={styles.featuresList}>
            {featuresData.map((feature, index) => (
              <article key={index} className={styles.featureItem}>
                <feature.icon className={styles.icon} />
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </article>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Features;
