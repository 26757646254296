import React, { useEffect } from "react";
import styles from "./styles/Enhance.module.scss";
import CompareImage from "react-compare-image";
import { FaSun, FaLightbulb, FaAdjust, FaEraser, FaEye } from "react-icons/fa";

const Enhance = () => {
  const leftImage = "https://i.hizliresim.com/oe49dfr.jpg";
  const rightImage = `https://i.hizliresim.com/84deiq1.png`;

  return (
    <div className={styles.container}>
      <h2>
        Transform Your Product Images with
        <br />
        <span className={styles.gradientText}>AI Clarity and Brilliance</span>
      </h2>
      <div className={styles.compareContainer}>
        <CompareImage
          leftImage={leftImage}
          rightImage={rightImage}
          className={"com"}
        />
      </div>
    </div>
  );
};

export default Enhance;
