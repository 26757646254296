import React from "react";
import styles from "./styles/Contact.module.scss";
import { FaFacebook, FaTwitter, FaInstagram, FaDiscord } from "react-icons/fa";

const ContactForm = () => {
  return (
    <div className={styles.contactFormContainer}>
      <div className={styles.contactFormContent}>
        <div className={styles.leftSide}>
          <h2>LET'S GET IN TOUCH TODAY!</h2>
          <p>
            Please use this form to get in touch with us. We would be happy to
            assist you with any inquiries or feedback you may have.
          </p>
          <a href="mailto:info@moonalisa.com" className={styles.emailLink}>
            info@moonalisa.com
          </a>
          <div className={styles.socialMedia}>
            <a href="#" className={styles.socialIcon}>
              <FaFacebook />
            </a>
            <a href="#" className={styles.socialIcon}>
              <FaTwitter />
            </a>
            <a href="#" className={styles.socialIcon}>
              <FaInstagram />
            </a>
            <a href="#" className={styles.socialIcon}>
              <FaDiscord />
            </a>
          </div>
        </div>
        <div className={styles.rightSide}>
          <form className={styles.form}>
            <input
              type="text"
              placeholder="Name*"
              className={styles.inputField}
            />
            <input
              type="email"
              placeholder="Email*"
              className={styles.inputField}
            />
            <input
              type="text"
              placeholder="Your inquiry about"
              className={styles.inputField}
            />
            <textarea
              placeholder="Message..."
              className={styles.textArea}
            ></textarea>

            <button type="submit" className={styles.submitButton}>
              Submit Now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
