import React, { useState } from "react";
import styles from "./styles/Pricing.module.scss";
import PopulerIcon from "./assets/populer2.png";

const Pricing = () => {
  const packagesData = [
    {
      id: 0,
      title: "Starter",
      price: "$29",
      period: "month",
      features: [
        "30 AI Product Enhancer uses",
        "Unlimited Background Generator",
        "Unlimited Background Removal",
        "Unlimited Object Removal",
        "Unlimited Virtual Dressing",
      ],
      buttonClass: styles.buyButton,
      aiEnhancerLimit: 30,
      aiEnhancerPrice: 0.0,
    },
    {
      id: 1,
      title: "Basic",
      price: "$39",
      period: "month",
      features: [
        "200 AI Product Enhancer uses",
        "Unlimited Background Generator",
        "Unlimited Background Removal",
        "Unlimited Object Removal",
        "Unlimited Virtual Dressing",
      ],
      buttonClass: styles.buyButtonPopular,
      popular: true,
      aiEnhancerLimit: 200,
      aiEnhancerMax: 2000,
      aiEnhancerPrice: 0.55,
    },
    {
      id: 2,
      title: "Premium",
      price: "$69",
      period: "month",
      features: [
        "400 AI Product Enhancer uses",
        "Unlimited Background Generator",
        "Unlimited Background Removal",
        "Unlimited Object Removal",
        "Unlimited Virtual Dressing",
      ],
      buttonClass: styles.buyButton,
      aiEnhancerLimit: 400,
      aiEnhancerMax: 2000,
      aiEnhancerPrice: 0.4,
    },
  ];

  const [additionalUses, setAdditionalUses] = useState(
    packagesData.map((pkg) => 0)
  );

  const handleAdditionalUsesChange = (index, e) => {
    const newAdditionalUses = [...additionalUses];
    newAdditionalUses[index] = Number(e.target.value);
    setAdditionalUses(newAdditionalUses);
  };

  const calculateTotalPrice = (pkg, index) => {
    const basePrice = parseFloat(pkg.price.slice(1));
    const additionalCost = additionalUses[index] * pkg.aiEnhancerPrice;
    return basePrice + additionalCost;
  };

  const renderFeatures = (features, additionalUses, limit) => {
    return features.map((feature, i) => {
      if (feature.includes("AI Product Enhancer uses")) {
        const [base] = feature.split(" AI Product Enhancer uses");
        return (
          <li key={i} className={styles.featureItem}>
            {base}
            {additionalUses > 0 && (
              <span className={styles.enhancedFeature}>
                {` + ${additionalUses}`}
              </span>
            )}{" "}
            AI Product Enhancer uses
            <img
              src={PopulerIcon}
              alt="Popular feature"
              className={styles.populerIcon}
            />
          </li>
        );
      }
      return (
        <li key={i} className={styles.featureItem}>
          {feature}
          {(feature.includes("Unlimited Background Generator") ||
            feature.includes("AI Product Enhancer uses")) && (
            <img
              src={PopulerIcon}
              alt="Popular feature"
              className={styles.populerIcon}
            />
          )}
        </li>
      );
    });
  };

  return (
    <div className={styles.pricingContainer}>
      <h1 className={styles.heading}>
        Upgrade <span>Your Photos</span> Instantly
      </h1>
      <p className={styles.subheading}>
        Unlock the Full Potential of Your Images with MagicVersion's AI-Powered
        Photo Editing Packages. Perfect for E-commerce,
        <br /> Social Media, and Personal Use. Enhance, Adjust, and Transform
        Your Photos Today!
      </p>

      <div className={styles.packages}>
        {packagesData.map((pkg, index) => (
          <div
            key={pkg.id}
            className={`${styles.package} ${pkg.popular ? styles.popular : ""}`}
          >
            <h2 className={styles[pkg.title.toLowerCase()]}>
              {pkg.title}{" "}
              {pkg.popular && (
                <span className={styles.popularTag}>POPULAR</span>
              )}
            </h2>
            <p className={styles.price}>
              <strong>${calculateTotalPrice(pkg, index).toFixed(2)}</strong> /{" "}
              {pkg.period}
            </p>
            <ul>
              {renderFeatures(
                pkg.features,
                additionalUses[index],
                pkg.aiEnhancerLimit
              )}
            </ul>
            <div
              style={{
                visibility: pkg.id !== 0 ? "visible" : "hidden",
              }}
              className={styles.additionalOptions}
            >
              <h3>Additional AI Product Enhancer Uses</h3>
              <input
                type="range"
                style={{ width: "100%" }}
                min={0}
                max={
                  pkg.aiEnhancerMax
                    ? pkg.aiEnhancerMax - pkg.aiEnhancerLimit
                    : 0
                }
                value={additionalUses[index]}
                onChange={(e) => handleAdditionalUsesChange(index, e)}
                disabled={!pkg.aiEnhancerMax}
              />
              <p>
                {additionalUses[index]} additional uses at $
                {pkg.aiEnhancerPrice} each
              </p>
            </div>
            <button
              style={{
                fontWeight: "bold",
              }}
              className={pkg.buttonClass}
            >
              Buy Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
