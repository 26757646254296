import React from "react";
import styles from "./styles/Footer.module.scss";

const stats = [
  { id: 1, title: "15+", description: "Ready to use React Components" },
  { id: 2, title: "60+", description: "Predefined colors for theme setup" },
  {
    id: 3,
    title: "</>",
    description: "Detailed documentation for each component",
  },
  { id: 4, title: "FREE", description: "Open source with regular updates" },
];

const links = [
  {
    category: "Docs",
    items: ["Getting Started", "API Reference", "Tutorials", "FAQ", "Support"],
  },
  {
    category: "Features",
    items: [
      "AI Photo Editing",
      "Background Removal",
      "Image Enhancement",
      "Batch Processing",
      "Integration with E-commerce",
    ],
  },
  {
    category: "Resources",
    items: ["Blog", "Case Studies", "White Papers", "Webinars", "Community"],
  },
  {
    category: "Company",
    items: ["About Us", "Careers", "Contact", "Press", "Privacy Policy"],
  },
  {
    category: "Extras",
    items: ["Blog", "Need Help?", "Give Feedback"],
  },
];

const Footer = () => {
  return (
    <footer className={styles.footer}>
      {/* <div className={styles.stats}>
        {stats.map((stat) => (
          <div key={stat.id} className={styles.statItem}>
            <h3>{stat.title}</h3>
            <p>{stat.description}</p>
          </div>
        ))}
      </div> */}
      {/* <div className={styles.notification}>
        <div className={styles.leftSide}>
          <h4>
            Your product photo enhance{" "}
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </h4>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Reprehenderit et vel.
          </p>
        </div>
        <button className={styles.notifyButton}>
          Get notified <span>→</span>
        </button>
      </div> */}
      <div className={styles.links}>
        {links.map((link) => (
          <div key={link.category}>
            <h5>{link.category}</h5>
            <ul>
              {link.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
