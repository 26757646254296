import React from "react";
import { AiOutlineClose } from "react-icons/ai"; // Import the close icon
import styles from "./Modal.module.scss";

const Modal = ({ show, onClose }) => {
  if (!show) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <AiOutlineClose
          className={styles.closeIcon}
          onClick={onClose}
          size={24}
        />
        <h2>Coming Soon</h2>
        <p>
          Our API will be available soon. Stay tuned for updates and get ready
          to integrate powerful AI-driven photo editing capabilities into your
          applications. For more information, please contact us.
        </p>
      </div>
    </div>
  );
};

export default Modal;
