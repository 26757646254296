import React from "react";
import styles from "./styles/Review.module.scss";

import { FaUserCircle } from "react-icons/fa";

const CustomerReviews = () => {
  const reviewsData = [
    {
      id: 1,
      rating: 5,
      title: "Amazing tool for my business!",
      content:
        "MagicVersion has revolutionized how I manage product photos for my e-commerce store. The AI tools are incredibly efficient and easy to use. Highly recommend!",
      author: "Jane Doe",
      position: "E-commerce Entrepreneur",
    },
    {
      id: 2,
      rating: 5,
      title: "Great features and support",
      content:
        "I've been using MagicVersion for several months, and it has significantly improved my workflow. The support team is also very responsive and helpful.",
      author: "John Smith",
      position: "Freelance Photographer",
    },
    {
      id: 3,
      rating: 5,
      title: "A must-have for any online seller!",
      content:
        "The background removal and product photo enhancer features are fantastic. They save me so much time and produce professional-quality photos. A game-changer!",
      author: "Alice Johnson",
      position: "Online Seller",
    },
  ];

  return (
    <div className={styles.reviewsContainer}>
      <h2 className={styles.reviewsHeading}>What Our Customers Say</h2>
      <div className={styles.reviews}>
        {reviewsData.map((review) => (
          <div key={review.id} className={styles.reviewCard}>
            <div className={styles.rating}>
              {"★".repeat(review.rating)}
              {"☆".repeat(5 - review.rating)}
            </div>
            <h3 className={styles.reviewTitle}>{review.title}</h3>
            <p className={styles.reviewContent}>{review.content}</p>
            <div className={styles.reviewAuthor}>
              <FaUserCircle
                size={40}
                color="gray"
                style={{
                  marginRight: "20px",
                }}
              />

              <div>
                <p className={styles.authorName}>{review.author}</p>
                <p className={styles.authorPosition}>{review.position}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerReviews;
